import React from "react";
import styled from "styled-components";
import DashboardImage from "../assets/backgroundImages/DashboardImage.jpg";

const Body = styled.div`
  ::after {
    content: "";
    background-image: url(${DashboardImage});
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    opacity: 0.5;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }
`;

const Dashboard = () => {
  return (
    <>
      <Body>
        <div className="content-container">
          <h1>Dashboard</h1>
        </div>
      </Body>
    </>
  );
};

export default Dashboard;
