import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import logger from "redux-logger";
import { rootReducer } from "./state/reducers";

import "./index.css";
import App from "./App";

const store = createStore(rootReducer, applyMiddleware(logger));
// https://heroku-deploying-api.herokuapp.com/
// https://codesandbox.io/s/potluck-planner-mp8gy

ReactDOM.render(
  <Router>
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>
  </Router>,
  document.getElementById("root")
);
