import axios from 'axios';

const API_BASE_URL ='https://heroku-deploying-api.herokuapp.com/';

export const axiosWithAuth = () => {
    return axios.create({
      baseURL: API_BASE_URL,
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
  };