import React from "react";
import { useHistory } from "react-router-dom";
import { states } from "../constants/index";
import { useForm } from "../hooks/useForm";
import { useAPI } from "../hooks/useAPI";
import { useSelector, useDispatch } from "react-redux";
import Dropdown from "./common/Dropdown";
import {
  USER_EVENT_START,
  USER_EVENT_SUCCESS,
  USER_EVENT_ERROR,
} from "../state/reducers/userReducer";
import styled from "styled-components";
import HomeImage from "../assets/backgroundImages/HomeImage.jpg";

const Body = styled.div`
  ::after {
    content: "";
    background-image: url(${HomeImage});
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    opacity: 0.5;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }
`;
const Form = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 300px;
  text-align: center;
`;
const initialFormValues = {
  first_name: "",
  last_name: "",
  email: "",
  address_one: "",
  address_two: "",
  city: "",
  state: "",
  zip: "",
  password: "",
};

const Home = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.userReducer);
  const [values, handleChanges, resetForm] = useForm(initialFormValues);
  let history = useHistory();
  const [data, moveData, error] = useAPI({
    method: "post",
    url: "/user/register",
    data: values,
  });

  const postRegister = () => {
    dispatch({ type: USER_EVENT_START });
    moveData()
      .then((res) => {
        console.log(res);
        localStorage.setItem("token", res.token);
        dispatch({
          type: USER_EVENT_SUCCESS,
          payload: res.user,
        });
        console.log(state);
        history.push("/dashboard");
        resetForm();
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: USER_EVENT_ERROR, payload: err });
      });
  };

  const register = (e) => {
    e.preventDefault();
    // console.log(values);
    postRegister();
  };
  return (
    <>
      <Body>
        <div className="content-container">
          <h1>Home</h1>
          <Form onSubmit={register}>
            <div>
              <input
                type="text"
                required
                name="first_name"
                placeholder="First Name"
                value={values.first_name}
                onChange={handleChanges}
              />
              <input
                type="text"
                required
                name="last_name"
                placeholder="Last Name"
                value={values.last_name}
                onChange={handleChanges}
              />
            </div>
            <div>
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={values.email}
                onChange={handleChanges}
              />
            </div>

            <div>
              <input
                type="text"
                name="address_one"
                placeholder="Address 1"
                value={values.address_one}
                onChange={handleChanges}
              />
              <input
                type="text"
                name="address_two"
                placeholder="Address 2"
                value={values.address_two}
                onChange={handleChanges}
              />
              <input
                type="text"
                name="city"
                placeholder="City"
                value={values.city}
                onChange={handleChanges}
              />
              <input
                type="text"
                name="zip"
                placeholder="Zip Code"
                value={values.zip}
                onChange={handleChanges}
              />

              <Dropdown
                data={states}
                name="state"
                value={values.state}
                onChange={handleChanges}
              />
            </div>

            <div>
              <input
                type="password"
                name="password"
                placeholder="password"
                value={values.password}
                onChange={handleChanges}
              />
            </div>

            {!state.loading ? (
              <button>CREATE MY ACCOUNT</button>
            ) : (
              <button disabled>Loading...</button>
            )}
          </Form>
        </div>
      </Body>
    </>
  );
};

export default Home;
