import React from "react";
import { Route, Switch, Link } from "react-router-dom";

import Dashboard from "./components/Dashboard";
import Help from "./components/Help";
import Home from "./components/Home";
import Login from "./components/Login";
import PrivateRoute from "./components/PrivateRoute";

import "./App.css";

function App() {
  return (
    <div>
      {/* <header>
        <h1>POTLUCK PLANNER</h1>
      </header> */}
      <nav>
        <Link to="/">Home</Link>
        <Link to="/dashboard">Dashboard</Link>
        <Link to="/login">Login</Link>
        <Link to="/help">Help</Link>
      </nav>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <PrivateRoute path="/dashboard">
          <Dashboard />
        </PrivateRoute>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/help">
          <Help />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
