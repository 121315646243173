import React from "react";
import { useHistory } from "react-router-dom";
import { useAPI } from "../hooks/useAPI";
import { useForm } from "../hooks/useForm";
import { useSelector, useDispatch } from "react-redux";
import {
  USER_EVENT_START,
  USER_EVENT_SUCCESS,
  USER_EVENT_ERROR,
} from "../state/reducers/userReducer";
import { SET_CURRENT_USER } from "../state/reducers/eventsReducer";
import styled from "styled-components";
import LoginImage from "../assets/backgroundImages/LoginImage.jpg";

const Body = styled.div`
  ::after {
    content: "";
    background-image: url(${LoginImage});
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    opacity: 0.5;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }
`;
const Form = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 300px;
  text-align: center;
`;
const initialFormValues = {
  email: "",
  password: "",
};

const Login = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.userReducer);
  const [values, handleChanges, resetForm] = useForm(initialFormValues);
  let history = useHistory();
  const [data, moveData, error] = useAPI({
    method: "post",
    url: "/user/login",
    data: values,
  });
  const postLogin = () => {
    dispatch({ type: USER_EVENT_START });
    moveData()
      .then((res) => {
        // console.log(res);
        localStorage.setItem("token", res.token);
        dispatch({
          type: USER_EVENT_SUCCESS,
          payload: res.user,
        });
        dispatch({
          type: SET_CURRENT_USER,
          payload: res.user.id,
        });
        history.push("/dashboard");
        resetForm();
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: USER_EVENT_ERROR, payload: err });
      });
  };

  const login = (e) => {
    e.preventDefault();
    postLogin();
  };

  return (
    <>
      <Body>
        <div className="content-container">
          <h1>Login</h1>
          <section>
            <h1>Login To Your Account</h1>
            <Form onSubmit={login}>
              <div>
                <input
                  type="text"
                  name="email"
                  placeholder="email"
                  value={values.email}
                  onChange={handleChanges}
                />
              </div>

              <input
                type="password"
                name="password"
                placeholder="password"
                value={values.password}
                onChange={handleChanges}
              />
              {!state.loading ? (
                <button>Log In</button>
              ) : (
                <button disabled>Loading...</button>
              )}
            </Form>{" "}
            ​
          </section>
        </div>
      </Body>
    </>
  );
};

export default Login;
